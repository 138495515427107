import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import StandardButton from 'svr/component-lib/Generic/Buttons/StandardButton'
import { useForm } from '@sevenrooms/core/form'
import { Form, MultiSelectTree, type TreeNode } from '@sevenrooms/core/ui-kit/form'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import MinMaxSelection from '../components/MinMaxSelection'
import { ModifierMinMaxSchema } from '../modifierTypes/ModifierMinMax.zod'
import type { Modifier, ModifiersGroups, ModifiersSelected, ModifiersToOptions } from '../modifierTypes/Modifiers'

interface SelectModifiersModalProps {
  close: () => void
  modifiers: Modifier[]
  modifiersGroups: ModifiersGroups[]
  handleModifiersChange: (
    value: {
      id: string
      name: string
      modifiersAvailable: string[]
    }[]
  ) => void
  modifiersGroupsSelected: ModifiersSelected[]
}

const SelectModifiersModal = ({
  close,
  modifiers,
  modifiersGroups: modifiersGroupsOptions,
  handleModifiersChange,
  modifiersGroupsSelected,
}: SelectModifiersModalProps) => {
  const [modifiersGroups, setModifiersGroups] = useState<ModifiersToOptions[]>([])
  const [selectedGroups, setSelectedGroups] = useState<ModifiersSelected[]>()
  const [alreadySelectedGroups, setAlreadySelectedGroups] = useState<ModifiersSelected[]>([])
  const form = useForm(ModifierMinMaxSchema, { mode: 'onChange' })
  const { field, watch, getValues } = form

  useEffect(() => {
    if (modifiersGroupsSelected) {
      setAlreadySelectedGroups(modifiersGroupsSelected)
    }
  }, [modifiersGroupsSelected])

  useEffect(() => {
    if (modifiers?.length && Array.isArray(modifiers) && modifiersGroupsOptions?.length) {
      const modifiersGroupsToOptions = modifiersGroupsOptions
        .map(group => {
          const modifiersFound = modifiers
            .filter(modifier => modifier.groups?.includes(group.name))
            .map(modifier => {
              const isChecked = alreadySelectedGroups
                ?.find(groupChecked => groupChecked.id === group.key)
                ?.modifiersAvailable.some(modifierChecked => modifierChecked === modifier.key)
              return {
                id: `${modifier.key}?group=${group.key}`,
                label: `${modifier.name} ${modifier.price ? `(+ $${modifier.price})` : ''}`,
                value: modifier.name,
                checked: !!isChecked,
              }
            })
          if (!modifiersFound.length) {
            return false
          }
          return {
            id: group.key,
            label: group.name,
            value: group.key,
            children: modifiersFound,
            checked: !modifiersFound.some(modifier => !modifier.checked),
          }
        })
        .filter(option => option)
      setModifiersGroups([
        {
          id: 'Modifiers',
          label: 'Modifiers',
          value: 'Modifiers',
          section: true,
          children: modifiersGroupsToOptions,
        } as ModifiersToOptions,
      ])
    }
  }, [modifiers, modifiersGroupsOptions, alreadySelectedGroups])

  const handleSelect = (itemsChecked: TreeNode<string>[]) => {
    const modifiersInGroup: { [x: string]: string[] } = {}

    const groupsChecked = itemsChecked.filter(item => !item.id.includes('?group=')).map(item => item.id)
    const modifiersChecked = itemsChecked.filter(item => item.id.includes('?group=')).map(item => item.id)

    for (const modifier of modifiersChecked) {
      const ids = modifier.split('?group=')
      if (ids[1] && ids[0]) {
        modifiersInGroup[ids[1]] = [...(modifiersInGroup[ids[1]] || []), ids[0]]
      }
    }
    const groupsParsed = modifiersGroupsOptions
      .map(group => {
        if (groupsChecked.includes(group.key)) {
          const possibleModifiersFound = modifiers.filter(modifier => modifier.groups?.includes(group.name)).map(modifier => modifier.key)
          return {
            id: group.key,
            name: group.name,
            modifiersAvailable: possibleModifiersFound,
          }
        }
        return {
          id: group.key,
          name: group.name,
          modifiersAvailable: modifiersInGroup[group.key] || [],
        }
      })
      .filter(group => group.modifiersAvailable.length)
    setSelectedGroups(groupsParsed)
  }

  const handleClose = () => {
    const minAndMaximum = getValues('minMax')
    const minAndMaximumInserted = minAndMaximum
      .filter(minMax => (selectedGroups || alreadySelectedGroups)?.some(group => group.name === minMax.group))
      .map(minMax => {
        const group = (selectedGroups || alreadySelectedGroups)?.find(group => group.name === minMax.group)
        return {
          id: group?.id || '',
          name: group?.name || '',
          modifiersAvailable: group?.modifiersAvailable || [],
          minRequired: minMax.minAmount,
          maxRequired: minMax.maxAmount,
        }
      })
    handleModifiersChange(minAndMaximumInserted)
    close()
  }

  return (
    <PageMask>
      <Content>
        <Typography variant="h2">Customize the menu</Typography>
        <MultiSelectTree
          containerWidth="100%"
          treeMaxHeight={200}
          treeData={modifiersGroups as TreeNode<string>[]}
          onChange={(_, itemsChecked) => handleSelect(itemsChecked)}
        />
        {typeof modifiers === 'string' && (
          <>
            <Typography variant="body2" color="darkred">
              We are unable to show modifiers right now.
            </Typography>
            <Typography variant="body2" color="darkred">
              Please refresh the page or try again later.
            </Typography>
          </>
        )}
      </Content>
      <FormContainer>
        <Form {...form} onSubmit={() => {}} onInvalid={() => {}}>
          <MinMaxSelection
            getValues={getValues}
            alreadySelectedGroups={alreadySelectedGroups}
            selectedGroups={selectedGroups}
            fields={field}
            watch={watch}
          />
        </Form>
      </FormContainer>
      <Footer>
        <StandardButton skin="DEFAULT" disabled={false} onClick={() => close()} customButton={ButtonPrimaryStyle}>
          Cancel
        </StandardButton>
        <StandardButton skin="DEFAULT" disabled={false} onClick={handleClose} customButton={ButtonPrimaryStyle}>
          Save
        </StandardButton>
      </Footer>
    </PageMask>
  )
}

const PageMask = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 10;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 2.5rem;
  box-sizing: border-box;
  overflow: hidden;
`

const Content = styled.div`
  flex: 0 0 80px;
  overflow-y: auto;
  padding-bottom: 10px;
`

const Footer = styled.div`
  background-color: #ffff;
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  box-sizing: border-box;
  border-top: 1px solid rgba(218, 218, 218, 1);
  padding: 8px 15px;
  & > button {
    margin: 8px 15px 8px 0;
  }
`

const FormContainer = styled.div`
  flex: 1;
  overflow-y: auto;
`

const ButtonPrimaryStyle = css`
  height: 44px;
  line-height: 44px;
  color: white;
  background-color: rgba(120, 156, 239, 1);
`

export default SelectModifiersModal
